<template>
  <section class="not-found-page full-width vh-100">
    <article class="container containner_md full-height flex row wrap items-center x-center white">
      <div>
        <h1 class="generalcardtitle">
          Error 404.
          <span class="block"> Ups! No hay nada por aquí.</span>
        </h1>
        <router-link class="block margin-top-20" to="/">Regresemos a la home</router-link>
      </div>
    </article>
  </section>
</template>